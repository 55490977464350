<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="foot-detail">
      <h3>关于我们</h3>
      <p>欢迎您光临车学堂网站，感谢您对车学堂的关注！</p>

      <p>
        车学堂是由人民交通出版社股份有限公司的子公司北京行翼科技有限公司（以下称“行翼科技”）建设，为驾驶员和驾驶培训机构搭建的网路远程教育服务平台。
      </p>

      <p>
        人民交通出版社是交通运输部直属单位，是财政部代表国务院履行出资人职责的中央文化企业之一。
      </p>

      <p>
        2014 年4 月16
        日，由人民交通出版社作为主发起人，联合中国科技产业投资管理有限公司共同发起的人民交通出版社股份有限公司正式设立，成为中央各部门各单位第一家完成股份制改造的单体出版社。
      </p>

      <p>
        人民交通出版社是我国驾驶培训内容的主要提供者。20
        多年来，人民交通出版社一直专注于驾培教育内容研发和驾培政策研究，参与了历次驾驶培训教学大纲与考试大纲的制（修）订。人民交通出版社出版的《安全驾驶从这里开始》驾驶员培训教材，先后被5000
        余万名驾驶学员选择使用，为我国驾驶培训行业健康有序发展提供了坚实的知识支撑。
      </p>

      <p>
        在内容上，全面涵盖了初学、增驾、从业资格、继续教育不同体系的安全驾驶知识网络培训课程。汇聚了业内顶尖专家，提供了最权威、规范和实用的教学内容，采用高清视频和情景动画等多种表现形式，吸引各类学习群体，直观有效地表现知识要点，提高学习效果。
      </p>

      <p>
        在规范上，符合最新的驾驶培训教学和考试大纲，符合最新的驾培网络远程教育标准。
      </p>

      <p>
        在平台上，可以准确、有效地计时，极大方便管理部门的监管；可以完美适配最新计时平台规范，无缝对接全国统一的驾培监管服务平台和公安交通安全综合服务管理平台。
      </p>

      <p>
        在服务上，5*8小时的服务保障，在课程学习、平台操作及其它个性化服务方面，追求客户满意度，并努力超越客户预期。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
